<template src="./ovisAdmin.html">
</template>

<script>
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import coursesService from "@/services/Courses";
import ovisService from "@/services/Ovis";

export default {
  name: "ovis",
  components: {
    ArrobaMedellinSeeker,
    ArrobaMedellinArrobitaNoData,
  },
  data() {
    return {
      allOvis: [],
      errorMessage: false,
      selectedFilter: 'own'
    }
  },
  methods: {
    redirectToLink(url) {
      window.location.href = url;
    },
    async getAllOvis() {
      try {
        if (this.selectedFilter === 'own') {
          this.allOvis = await coursesService.getAllOvisMyUser();
        } else if (this.selectedFilter === 'others') {
          this.allOvis = await coursesService.getAllOvis();
        }
      } catch (e) {
        console.log('e', e);
      }
    },
    async searchByWord(word) {
      try {
        if (this.selectedFilter === 'own') {
          this.allOvis = await coursesService.getOvisUserWord(word);
        } else if (this.selectedFilter === 'others') {
          this.allOvis = await coursesService.getOvisWord(word);
        }
      } catch (e) {
        console.log('e', e);
      }
    },
    open(url) {
      window.open(url, "_blank");
    },
    deleteOvi(id) {
      console.log(id);
      this.$buefy.dialog.confirm({
        title: 'Eliminar un OVI',
        message: '¿Estas seguro de eliminar este objetivo virtual de información?',
        type: 'is-warning is-outlined',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        size: 'is-small',
        onConfirm: async () => {
          try {
            await ovisService.deleteOvis(id);
            this.getAllOvis();
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Ovi eliminado exitosamente',
              position: 'is-bottom',
              type: 'is-success'
            })
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: "Ocurrió un error al eliminar el ovi",
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        }
      })
    },
  },
  watch: {
    selectedFilter() {
      this.getAllOvis();
    }
  },
  mounted() {
    this.getAllOvis();
  }
}
</script>

<style scoped lang="scss" src="./ovisAdmin.scss">
.academic-panel {
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    url(../../assets/img/academico-coltejer-v2.jpg) no-repeat fixed;
  background-size: cover;
  min-height: 100vh;
  width: 100%;

}
</style>
